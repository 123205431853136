import commonHelper from '@/app/utility/common.helper.utility';

export default {
  name: 'fusion',
  props: ['searchParams', 'editMode'],
  watch: {
    searchParams() {
      if (this.searchParams) {
        this.getFusionData(this.searchParams);
      } else {
        this.fusionData = [];
      }
    },
    currentPage: function() {
      // this.searchParams._page = this.currentPage - 1;
      this.getFusionData(this.searchParams);
    },

    perPage: function() {
      this.currentPage1 = 1;
      this.getFusionData(this.searchParams);
    },
    totalRows() {
      this.$emit('totalRowsCount', this.totalRows);
    },
    editMode() {
      this.edit = this.editMode;
    }
  },
  data() {
    return {
      edit: false,
      loading: false,
      currentPage: 1,
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      selectAllCheckBox: false,
      fusionData: [],
      fusionFields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'info',
          class: 'col-checkbox'
        },
        {
          key: 'LE_NAME',
          label: 'Legal Entity',
          class: 'text-center'
        },
        {
          key: 'CUSTOMER_NAME',
          label: 'Customer Name',
          class: 'text-center'
        },
        {
          key: 'ACCOUNT_NUMBER',
          label: 'Customer Number',
          class: 'text-center'
        },
        {
          key: 'EMAIL',
          label: 'Email',
          class: 'text-center col-width-fusion'
        },
        {
          key: 'OU_NAME',
          label: 'OU Name',
          class: 'text-center'
        },
        {
          key: 'TRX_TYPE',
          label: 'Transaction Type',
          class: 'text-center'
        },
        {
          key: 'TRX_NUMBER',
          label: 'Transaction Number',
          class: 'text-center'
        },
        {
          key: 'CUSTOMER_TRX_ID',
          label: 'Customer Transaction ID',
          class: 'text-center'
        },
        {
          key: 'TRX_DATE',
          label: 'Transaction Date',
          class: 'text-center'
        },
        {
          key: 'AMOUNT',
          label: 'Amount',
          class: 'text-center'
        },
        {
          key: 'PARTY_SITE_NAME',
          label: 'Party Site Name',
          class: 'text-center'
        },
        {
          key: 'PARTY_SITE_NUMBER',
          label: 'Party Site Number',
          class: 'text-center'
        },
        {
          key: 'INVOICE_CLASS',
          label: 'Transaction Class',
          class: 'text-center'
        },
        {
          key: 'INVOICE_CURRENCY_CODE',
          label: 'Invoice Currency Code',
          class: 'text-center'
        },
        {
          key: 'PARTY_SITE_NAME',
          label: 'Party Site Name',
          class: 'text-center'
        }
      ],
      rowCount: 500,
      selectedRow: [],
      rowSelected: [],
      selectedRecords: 0,
      defaultEmail: [],
      mapFlag: false
    };
  },
  mounted() {
    if (this.searchParams) {
      this.getFusionData(this.searchParams);
    }
  },
  methods: {
    getFusionData(params) {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.searchParams.perPage,
        sTrxCustID: params.trxNum,
        sCustNum: params.customerNumber,
        sCustName: params.customerName,
        sTrxType: params.trxType,
        sFromDate: this.convertDateFormat(params.fromDate),
        sToDate: this.convertDateFormat(params.toDate),
        sLeId: params.leId,
        sTrxClass: params.trxClass,
        rowcount: this.rowCount
      };
      this.loading = true;
      this.$store
        .dispatch('arInvoicePrint/getFusion', payload)
        .then(response => {
          this.loading = false;
          if (response.status === 200) {
            this.fusionData = response.data.data.data;
            this.fusionData.map(ele => {
              const emailStr = ele.EMAIL;
              ele.EMAIL = ele.EMAIL
                ? ele.EMAIL.split(',').map(email => email.trim())
                : [];
              if (ele.EMAIL.length === 1 && emailStr.includes('@')) {
                ele.EMAIL = [emailStr.trim()];
              }
            });
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    selectBoxChecked(flag, index, item, trxId) {
      this.fusionData[index].selectBox = flag;
      if (flag === true) {
        this.selectedRow.push(item);
        this.selectedRecords = this.selectedRecords + 1;
      } else {
        this.selectedRow = this.selectedRow.filter(
          el => el.CUSTOMER_TRX_ID !== trxId
        );
        this.selectedRecords = this.selectedRecords - 1;
      }
    },
    selectAllBoxChecked(flag) {
      this.selectedRow = [];
      if (flag) {
        this.fusionData = this.fusionData.map((data, index) => {
          data.selectBox = this.selectAllCheckBox;
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
          return data;
        });
        this.selectedRow = this.fusionData;
      } else {
        this.fusionData = this.fusionData.map((data, index) => {
          data.selectBox = this.selectAllCheckBox;
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
          return data;
        });
      }
    },
    // validateEmail(email,field) {
    //   // let email_list = email_str.split(',').map(email => email.trim());
    //   // for (let i = 0; i < email_list.length; i++) {
    //     if (email) {
    //       if (
    //         !/^[a-z0-9._-]+@[a-z0-9._-]+\.[a-z]{2,6}$/.test(
    //           email.toLowerCase()
    //         )
    //       ) {
    //         alert('Please Enter Valid Email Id');
    //         // this.fusionData[index].EMAIL = null;
    //       } else {
    //         if (field == 'defaultType') {
    //           this.mapFlag = true;
    //         } else {
    //           this.mapFlag = false;
    //         }
    //       }
    //     // }
    //   }
    // },

    validateEmail(field) {
      let emilErrorVal = '';
      for (let i = 0; i < this.defaultEmail.length; i++) {
        if (this.defaultEmail[i]) {
          if (
            !/^[a-z0-9._-]+@[a-z0-9._-]+\.[a-z]{2,6}$/.test(
              this.defaultEmail[i].toLowerCase()
            )
          ) {
            if (i == this.defaultEmail.length - 1) {
              emilErrorVal += this.defaultEmail[i];
            } else {
              emilErrorVal += this.defaultEmail[i] + ',' + ' ';
            }
          }
        }
      }
      if (emilErrorVal.length) {
        alert('Wrong email format for these inputs:' + ' ' + emilErrorVal);
      } else {
        if (field == 'defaultType') {
          this.mapFlag = true;
          this.mapEmailFunc();
        } else {
          this.mapFlag = false;
        }
      }
    },
    convertDateFormat(inputDate) {
      if (inputDate) {
        const parsedDate = new Date(inputDate);
        const year = parsedDate.getFullYear();
        const month = (parsedDate.getMonth() + 1).toString().padStart(2, '0');
        const day = parsedDate
          .getDate()
          .toString()
          .padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
      }
    },
    validateEmailGrid(emailList) {
      for (let i = 0; i < emailList.length; i++) {
        if (emailList[i]) {
          if (
            !/^[a-z0-9._-]+@[a-z0-9._-]+\.[a-z]{2,6}$/.test(
              emailList[i].toLowerCase()
            )
          ) {
            alert('Wrong email format:' + ' ' + emailList[i]);
            emailList[i] = null;
          }
        }
      }
    },
    mapEmailFunc() {
      if (this.mapFlag) {
        for (let i = 0; i < this.selectedRow.length; i++) {
          if (this.selectedRow[i].EMAIL.length) {
            let combinedArray = [
              ...this.selectedRow[i].EMAIL,
              ...this.defaultEmail
            ];
            let uniqueArray = [...new Set(combinedArray)];
            this.selectedRow[i].EMAIL = uniqueArray;
          } else {
            this.selectedRow[i].EMAIL = this.defaultEmail;
          }
        }
      }
    },
    saveFusion() {
      let savePayload = [];
      const checkEmailNull = this.selectedRow.filter(
        data => !data.EMAIL.length
      );
      if (checkEmailNull.length) {
        alert('Please enter Email Id');
      } else {
        for (let i of this.selectedRow) {
          savePayload.push({
            account_num: i.ACCOUNT_NUMBER,
            cust_account_id: +i.CUST_ACCOUNT_ID,
            cust_name: i.CUSTOMER_NAME,
            cust_trx_id: +i.CUSTOMER_TRX_ID,
            inv_currency_code: i.INVOICE_CURRENCY_CODE,
            le_id: +i.LEGAL_ENTITY_ID,
            le_name: i.LE_NAME,
            org_id: +i.ORG_ID,
            ou_name: i.OU_NAME,
            party_site_name: i.PARTY_SITE_NAME,
            party_site_num: i.PARTY_SITE_NUMBER,
            trx_class: i.TRX_CLASS,
            trx_date: i.TRX_DATE,
            trx_num: i.TRX_NUMBER,
            trx_type: i.TRX_TYPE,
            run_id: null,
            email: i.EMAIL,
            amount: +i.AMOUNT
          });
        }
        const payload = {
          fusion_ar_inv_dtls: savePayload
        };
        this.loading = true;
        this.$store
          .dispatch('arInvoicePrint/saveFusion', payload)
          .then(response => {
            this.loading = false;
            if (response.status === 201) {
              // this.getFusionData(this.searchParams);
              this.$emit('searchSavedFusion', response.data.data);
              this.edit = false;
              this.eventBus.$emit('edit', false);
            } else {
              this.edit = false;
              this.eventBus.$emit('edit', false);
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    }
  }
};
