import commonHelper from '@/app/utility/common.helper.utility';

export default {
  name: 'Save',
  props: ['searchParams'],
  watch: {
    searchParams() {
      if (this.searchParams) {
        this.getSaveFusionData(this.searchParams);
      } else {
        this.savedFusionData = [];
      }
    },
    currentPage: function() {
      this.searchParams._page = this.currentPage - 1;
      this.getSaveFusionData(this.searchParams);
    },
    perPage: function() {
      this.currentPage1 = 1;
      this.getSaveFusionData(this.searchParams);
    },
    totalRows() {
      this.$emit('totalRowsCount', this.totalRows);
    }
  },
  data() {
    return {
      loading: false,
      currentPage: 1,
      totalRows: null,
      loader: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      savedFusionData: [],
      savedFusionFields: [
        {
          key: 'le_name',
          label: 'Legal Entity',
          class: 'text-center'
        },
        {
          key: 'cust_name',
          label: 'Customer Name',
          class: 'text-center'
        },
        {
          key: 'account_num',
          label: 'Customer Number',
          class: 'text-center'
        },
        {
          key: 'email_IDs',
          label: 'Email'
          // class: 'text-center'
        },
        {
          key: 'ou_name',
          label: 'OU Name',
          class: 'text-center'
        },
        {
          key: 'trx_type',
          label: 'Transaction Type',
          class: 'text-center'
        },
        {
          key: 'trx_num',
          label: 'Transaction Number',
          class: 'text-center'
        },
        {
          key: 'cust_trx_id',
          label: 'Customer Transaction ID',
          class: 'text-center'
        },
        {
          key: 'trx_date',
          label: 'Transaction Date',
          class: 'text-center'
        },
        {
          key: 'run_id',
          label: 'Run ID',
          class: 'text-center'
        },
        {
          key: 'amount',
          label: 'Amount',
          class: 'text-center'
        },
        {
          key: 'party_site_name',
          label: 'Party Site Name',
          class: 'text-center'
        },
        {
          key: 'party_site_num',
          label: 'Party Site Number',
          class: 'text-center'
        },
        {
          key: 'trx_class',
          label: 'Transaction Class',
          class: 'text-center'
        },
        {
          key: 'inv_currency_code',
          label: 'Invoice Currency Code',
          class: 'text-center'
        },
        {
          key: 'fusion_job_id',
          label: 'Request Id',
          class: 'text-center'
        },
        {
          key: 'refresh',
          class: 'text-center'
        },
        {
          key: 'status',
          label: 'Status',
          class: 'text-center'
        }
      ]
    };
  },
  mounted() {
    if (this.searchParams) {
      this.getSaveFusionData(this.searchParams);
    }
  },
  methods: {
    getSaveFusionData(params) {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.searchParams.perPage,
        runId: params.runId,
        sTrxCustID: params.trxNum,
        sCustNum: params.customerNumber,
        sCustName: params.customerName,
        sTrxType: params.trxType,
        sFromDate: this.convertDateFormat(params.fromDate),
        sToDate: this.convertDateFormat(params.toDate),
        sLeId: params.leId,
        sTrxClass: params.trxClass,
        rowcount: this.rowCount
      };
      this.loading = true;
      this.$store
        .dispatch('arInvoicePrint/getSavedFusionData', payload)
        .then(response => {
          this.loading = false;
          if (response.status === 200) {
            this.savedFusionData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    refreshSaveFusionDataStatus(item, index) {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.searchParams.perPage,
        runId: item.run_id,
        sTrxCustID: item.cust_trx_id
      };
      this.loader = true;
      this.$store
        .dispatch('arInvoicePrint/getSavedFusionData', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data.page;
            this.savedFusionData[index].status =
              result[0].status;
            this.savedFusionData[index].fusion_job_id = result[0].fusion_job_id;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    convertDateFormat(inputDate) {
      if (inputDate) {
        const parsedDate = new Date(inputDate);
        const year = parsedDate.getFullYear();
        const month = (parsedDate.getMonth() + 1).toString().padStart(2, '0');
        const day = parsedDate
          .getDate()
          .toString()
          .padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
      }
    }
  }
};
