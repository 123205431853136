import DatePicker from 'vue2-datepicker';
import appStrings from '@/app/utility/string.utility';
import commonHelper from '@/app/utility/common.helper.utility';
import saved from './saved';
import fusion from './fusion';
import responseModal from '../../shared/responseModal';

export default {
  name: 'arInvoicePrint',
  watch: {
    currentPage: function() {
      this.mainSearch();
    },
    perPage: function() {
      this.currentPage = 1;
      this.mainSearch();
    },
    editMode: function() {
      this.eventBus.$on('edit', edit => {
        this.editMode = edit;
      });
    }
    // currentPage1: function() {
    //   if (this.showOpenLegalEntity) {
    //     this.getLOVBySetType('LEGAL_ENTITY_LIST_FUSION');
    //   }
    // },
    // perPage1: function() {
    //   this.currentPage1 = 1;
    //   if (this.showOpenLegalEntity) {
    //     this.getLOVBySetType('LEGAL_ENTITY_LIST_FUSION');
    //   }
    // }
  },
  components: {
    DatePicker,
    saved,
    fusion,
    responseModal
  },
  data() {
    return {
      loading: false,
      loader: false,
      legalEntity: {
        text: null,
        value: null
      },
      transactionType: {
        text: null,
        value: null
      },
      transactionNumber: {
        text: null,
        value: null
      },
      customerName: {
        text: null,
        value: null
      },
      customerNumber: {
        text: null,
        value: null
      },
      trxClass: {
        text: null,
        value: null
      },
      runId: {
        text: null,
        value: null
      },
      // runId: null,
      fromDate: null,
      toDate: null,
      totalRows: null,
      totalRows1: null,
      perPage: 20,
      perPage1: 10,
      pageOptions: commonHelper.getPageOption(),
      tabIndex: 0,
      searchParams: {},
      editMode: false,
      showValueSetModal: false,
      fusion_key: 'FUSION',
      showOpenLegalEntity: false,
      currentPage: 1,
      currentPage1: 1,
      legalEntityData: [],
      legalEntityFields: [
        {
          key: 'LEGAL_ENTITY_ID',
          label: 'Legal Entity Id'
        },
        {
          key: 'NAME',
          label: 'Name'
        }
      ],
      activeTab: 'Fusion'
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'update') {
          this.editMode = !this.editMode;
        }
      }
    });
  },
  methods: {
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ['bg-success', 'text-light'];
      } else {
        return ['bg-light', 'text-info'];
      }
    },
    selectTab(tab) {
      this.activeTab = tab;
      this.totalRows = null
    },
    searchSavedFusion(runId) {
      this.runId.value = runId;
      this.runId.text = runId
      this.tabIndex = 1;
      this.selectTab('Saved');
      this.linkClass(1);
      this.mainSearch()
    },
    totalRowsCount(item) {
      this.totalRows = item;
    },
    // openCloseResponseModal(item) {
    //   if (item === 'Legal Entity') {
    //     this.getLOVBySetType('LEGAL_ENTITY_LIST_FUSION');
    //     this.showOpenLegalEntity = true;
    //   }
    // },
    // responseModal(flag) {
    //   this.showOpenLegalEntity = flag;
    // },
    // legalEntityModal(flag) {
    //   this.showOpenLegalEntity = flag;
    // },
    // getLOVBySetType(valueSetName, searchText) {
    //   this.loading = true;
    //   const payload = {
    //     valueSetName: valueSetName,
    //     dependentVariable: {
    //       search_key: searchText,
    //       _page: this.currentPage1 - 1,
    //       res_id: this.$store.state.shared.responsibilityId,
    //       user_id: this.$store.state.auth.userId
    //       // _limit: this.perPage1
    //     }
    //   };
    //   this.$store
    //     .dispatch('shared/getCommonVSetData', payload)
    //     .then(response => {
    //       this.loading = false;
    //       if (response.status === 200) {
    //         this.legalEntityData = response.data.data.page;
    //         this.totalRows1 = response.data.data.total_elements;
    //       }
    //     })
    //     .catch(error => {
    //       this.loading = false;
    //       alert(error.message);
    //     });
    // },
    // selectedLegalEntity(flag, legalEntity) {
    //   this.showOpenLegalEntity = flag;
    //   this.legalEntity = {
    //     value: legalEntity.legalEntityId,
    //     text: legalEntity.name
    //   };
    // },
    mainSearch() {
      this.searchParams = {
        leId: this.legalEntity.value,
        runId: this.runId.value,
        trxType: this.transactionType.value,
        trxNum: this.transactionNumber.value,
        customerNumber: this.customerNumber.text,
        customerName: this.customerName.text,
        trxClass: this.trxClass.value,
        fromDate: this.fromDate,
        toDate: this.toDate,
        perPage: this.perPage
      };
    },
    clear() {
      this.legalEntity = { text: null, value: null };
      this.runId = { text: null, value: null };
      this.transactionType = { text: null, value: null };
      this.transactionNumber = { text: null, value: null };
      this.customerNumber = { text: null, value: null };
      this.customerName = { text: null, value: null };
      this.trxClass = { text: null, value: null };
      this.fromDate = null;
      this.toDate = null;
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      // this.parent_value_set_id = null;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST_FUSION) {
        this.legalEntity = {
          text: item.NAME,
          value: item.LEGAL_ENTITY_ID
        };
      } else if (
        this.vsetCode ===
        appStrings.VALUESETTYPE.PARTY_NAME_WITH_ACCOUNT_NUMBER_FUSION
      ) {
        this.customerNumber.text = item.ACCOUNT_NUMBER;
        this.customerName.text = item.PARTY_NAME;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.TRX_TYPE_FUSION) {
        this.transactionType.text = item.value_code;
        this.transactionType.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.TRX_NUMBER_FUSION) {
        this.transactionNumber.text = item.TRX_NUMBER;
        this.transactionNumber.value = item.CUSTOMER_TRX_ID;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.TRANSACTION_CLASS_FUSION
      ) {
        this.trxClass.text = item.value_meaning;
        this.trxClass.value = item.value_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.GET_RUN_ID) {
        this.runId.text = item.value_set_dtl_id;
        this.runId.value = item.value_set_dtl_id;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.legalEntity.value) {
        this.legalEntity = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.customerNumber.text) {
        this.customerNumber = {
          text: null,
          value: null
        };
        this.customerName = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.transactionType.value) {
        this.transactionType = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.transactionNumber.value) {
        this.transactionNumber = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.trxClass.value) {
        this.trxClass = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.runId.value) {
        this.runId = {
          text: null,
          value: null
        };
      }
    },
    disabledDates(date) {
      return commonHelper.disabledDates(date, this.fromDate);
    },
    clearSearchParams(params) {
      if (params === 'Legal Entity') {
        this.legalEntity = {
          value: null,
          text: null
        };
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
    this.eventBus.$off('edit');
  }
};
